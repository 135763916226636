import { StepSyncProgress, IStepSyncProgressOptions } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';

export interface ICacheAppProgressOptions extends IStepSyncProgressOptions {}

export class CacheAppProgress extends StepSyncProgress {
    public readonly progressComponentId = 'CacheAppProgress';

    constructor(options: ICacheAppProgressOptions) {
        super(options);
    }
}
